<template>
        <ListButton url="/" image="images/tpv/cashier.png" text="Venta" />
        <ListButton url="/caja" image="images/tpv/cash-machine.png" text="Caja" />
        <ListButton url="/configuracion" image="images/tpv/configuracion.png" text="Config. Terminal" />
        <ListButton url="/productos" image="images/tpv/products.png" text="Config. Productos" />
        <ListButton url="/promociones" image="images/tpv/promocion.png" text="Marketing" />
        <ListButton url="/clientes" image="images/tpv/customer.png" text="Clientes" />
        <ListButton url="/inventario" image="images/tpv/inventario.png" text="Inventario" />
        <ListButton url="/impresoras" image="images/tpv/impresora.png" text="Impresora" />
        <ListButton url="/reportes" image="images/tpv/reportes.png" text="Reportes" />
</template>
<script>
import ListButton from "./components/general/ListButton.vue";

export default {
    components: { ListButton },
    methods: {
        go(route) {
            this.$router.push(route)
        }
    }
}
</script>

<style>
.center {
  display: block;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
</style>